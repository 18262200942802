import { type UIMatch, useMatches } from '@remix-run/react'

interface Features {
  nav: {
    search: boolean
    accounts: boolean
  }
  footer: boolean
}

// Use the type to constrain featurePath
export function shouldShowFeature(featurePath: NestedKeyOf<Features>) {
  const matches = useMatches() as UIMatch<unknown, { features: Features }>[]

  return matches.every((m) => {
    const features = m.handle?.features
    if (!features) return true

    const featureKeys = featurePath.split('.')

    // biome-ignore lint/suspicious/noExplicitAny: Needed for possibility of nested object or boolean
    let currentFeature: any = features

    for (const key of featureKeys) {
      if (typeof currentFeature[key] === 'undefined') {
        return true
      }
      currentFeature = currentFeature[key]
    }

    return currentFeature === true
  })
}

export function shouldHideFeature(featurePath: NestedKeyOf<Features>) {
  const matches = useMatches() as UIMatch<unknown, { features: Features }>[]

  return matches.some((m) => {
    const features = m.handle?.features
    if (!features) return false

    const featureKeys = featurePath.split('.')
    // biome-ignore lint/suspicious/noExplicitAny: Needed for possibility of nested object or boolean
    let currentFeature: any = features

    for (const key of featureKeys) {
      if (typeof currentFeature[key] === 'undefined') {
        return false
      }
      currentFeature = currentFeature[key]
    }

    return currentFeature === false
  })
}
